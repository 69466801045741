<template>
    <div class="header_space">
        <table id="navigation_table">
            <tr>
                <td class="navigation_td main_font">
                    <div id="sign-in">
                        <a
                            class="navigation_link"
                            :href="signInUrl"
                        >
                            Sign in
                        </a>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="navigation_td main_font">
                    <router-link class="navigation_link" to="/files">
                        Files
                    </router-link>
                </td>
            </tr>
            <tr>
                <td class="navigation_td main_font">
                    <router-link class="navigation_link" to="/render">
                        Render
                    </router-link>
                </td>
            </tr>
            <tr>
                <td class="navigation_td main_font">
                    <router-link
                        class="navigation_link"
                        to="/settings"
                        @click.native="testfunc"
                    >
                        Settings
                    </router-link>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import readCookie from '../util/readCookie';

const log = (message) => console.log('Navigation:', message);

function testfunc() {
    log('testfunc');
    this.$root.$emit('testvar_changed', 'ufffffff');
}

function setHeader() {
    this.$root.$emit('set_header_title', this.header);
}

export default {
    data() {
        return {
            header: {
                title: 'Navigation',
            },
            signInUrl: process.env.VUE_APP_SIGN_IN_URL,
            signOutUrl: process.env.VUE_APP_SIGN_OUT_URL,
        };
    },

    mounted() {
        log('navigation');
        this.setHeader();

        const idToken = readCookie('id_token');

        if (idToken) {
            log('there is an id_token cookie, user is signed in');

            // TODO: use proper vue techniques
            document.getElementById('sign-in').innerHTML = `
                    <a
                        class="navigation_link"
                        onclick="document.cookie='id_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'"
                        href="${this.signOutUrl}"
                        >
                        Sign out
                    </a>
                `;
        }
    },

    methods: {
        setHeader,
        testfunc,
    },
};
</script>

<style scoped>
#navigation_table {
    width: 100%;
    table-layout: fixed;
}

.navigation_td {
    padding: 3vh;
}
</style>
